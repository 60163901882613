<!--
 * @Author       : Hugo
 * @Date         : 2022-04-15 16:56:39
 * @LastEditTime : 2022-04-25 14:25:10
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/views/test/html2canvas.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div>
    <div class="html2canvas" id="html2canvas_test">
      测试图片3
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
export default {
  mounted(){
    console.log(html2canvas)
    const ele = document.querySelector('#html2canvas_test')
    html2canvas(ele).then((canvas) => {
      console.log(canvas)
      this.copyCanvas(canvas)
      // let src = canvas.toDataURL() // 获取图片url 地址
      // that.copyImage(src) // 拷贝图片
      // that.copyImage2(src) // 拷贝图片
      // document.body.appendChild(canvas);
    });
  },
  methods: {
    copyCanvas(canvas){
      // console.log(canvas)
      if(canvas){
        const src = canvas.toDataURL()
        this.copyImage(src)
      }
    },
  

    copyImage(url){
      const image = new Image()
      // 执行跨域请求获取图片
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function () {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        // 使用canvas 将图片转换成为image/png的blob数据
        console.log(canvas)
        canvas.toBlob(function (data) {
          // clipboard.write 仅支持image/png的blob数据
          navigator.clipboard.write([
            new window.ClipboardItem({
              [data.type]: data,
            }),
          ])
        }, 'image/png')
      }
      image.src = url + '?timestamp=' + Date.now()
    }
  },
}
</script>

<style lang="scss" scoped>
.html2canvas{
  font-size: 60px;
  color: orangered;
  text-align: center;
  padding: 40px 0;
}
</style>